import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

export const TopSection = () => (
    <TopSectionContainer>
        <TopSectionContent>

            <TopSectionTitle
                swichPoint={LAPTOP_MOBILE_POINTER}
                variant="h1"
            >Зарабатывайте вместе с нами
            </TopSectionTitle>
            <TopSectionDescription
            >Станьте партнером Самозанятые.рф — расскажите о нашем сервисе и получайте ежемесячный доход за каждого приведенного клиента
            </TopSectionDescription>

                <TopSectionBtn
                    onClick={() => {
                        document.querySelector('#quizSection').scrollIntoView({behavior: 'smooth'});
                    }}
                >
                    Стать партнером
                </TopSectionBtn>

        </TopSectionContent>

        <TopSectionImage>
            <StaticImage
                src="../../../images/partner/TopSectionImage.png"
                loading="eager"
                placeholder="none"
                formats={["auto", "webp"]}
                width={442}
                height={320}
                layout="fixed"
                objectFit="contain"
                alt=""
            />
        </TopSectionImage>

    </TopSectionContainer>
);

const TopSectionContainer = styled.section`
    display: flex;
    margin-top: 130px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
    }
`;

const TopSectionContent = styled.div`
    width: 595px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const TopSectionTitle = styled(TypographyUI.H1)`
    span {
        color: var(--accent-main);
    }

    margin-bottom: 16px;
`;

const TopSectionDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
    margin-bottom: 48px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 40px;
    }
`;

const TopSectionImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        display: none;
    }
`;

const TopSectionBtn = styled(ButtonUI)`
    width: 240px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;
