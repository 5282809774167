import styled from "styled-components";
import React from "react";
import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {StaticImage} from "gatsby-plugin-image";
import {useWindowSize} from "@tools/hooks";

export const WhoBecomePartnerSection = () => {

    const {width} = useWindowSize();

    return (
        <>
            <WhoBecomePartnerSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                Кто может стать партнером «Бизнес»
            </WhoBecomePartnerSectionTitle>
            <WhoBecomePartnerSectionContainer>
                <WhoBecomePartnerSectionItem style={{
                    'width': '100%',
                    'height': width && width > LAPTOP_MOBILE_POINTER ? '337px' : '100%',
                    'background': 'var(--background-second)',
                }}>
                    <WhoBecomePartnerSectionInfo>
                        <WhoBecomePartnerSectionInfoTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                            Руководители компаний: юридические лица и индивидуальные предприниматели
                        </WhoBecomePartnerSectionInfoTitle>
                        <WhoBecomePartnerSectionInfoDescription>
                            Приведите нового клиента в «Бизнес» — расскажите о нашей платформе руководителю другой
                            компании
                        </WhoBecomePartnerSectionInfoDescription>
                    </WhoBecomePartnerSectionInfo>

                    <WhoBecomePartnerSectionImage>
                        <StaticImage
                            src="../../../images/partner/WhoBecomePartnerSectionImage.png"
                            loading="eager"
                            placeholder="none"
                            formats={["auto", "webp"]}
                            layout="constrained"
                            objectFit="contain"
                            alt=""
                        />
                    </WhoBecomePartnerSectionImage>
                </WhoBecomePartnerSectionItem>
            </WhoBecomePartnerSectionContainer>

        </>
    )

}

const WhoBecomePartnerSectionTitle = styled(TypographyUI.H2)`
    margin: 40px auto 0;
    text-align: center;
`;

const WhoBecomePartnerSectionContainer = styled.section`
    margin: 40px 0px 60px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 40px 0 80px;
    }
    @media (max-width: 609px) {
        margin: 40px -19px 80px;
    }
`;

const WhoBecomePartnerSectionItem = styled.section`
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
    padding-bottom: 30px;
    overflow: hidden;
    position: relative;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding: 30px 20px;
    }
    @media (max-width: 609px) {
        border-radius: 0px;
    }

`;

const WhoBecomePartnerSectionInfo = styled.div`
    max-width: 460px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
}
`;

const WhoBecomePartnerSectionInfoTitle = styled(TypographyUI.H3)`
    margin-bottom: 24px;
`;
const WhoBecomePartnerSectionInfoDescription = styled(TypographyUI.Body1Regular)`
    margin-bottom: 24px;
    color: var(--text-secondary);
`;

const WhoBecomePartnerSectionImage = styled.div`
    width: 285px;
    position: absolute;
    bottom: 0;
    right: 96px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        position: relative;
        bottom: -30px;
        left: 0;
        right: 0;
        max-width: 300px;
        margin: 0 auto;
    }
`;




