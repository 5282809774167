import React from "react";
import styled from "styled-components";

import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {ButtonUI, TypographyUI} from "@ui";
import srcImg1 from "@images/partner/AdvantagesPartnerSectionImage1.png";
import srcImg2 from "@images/partner/AdvantagesPartnerSectionImage2.png";
import srcImg3 from "@images/partner/AdvantagesPartnerSectionImage3.png";
import srcImg4 from "@images/partner/AdvantagesPartnerSectionImage4.png";
import {useWindowSize} from "@tools/hooks";

export const AdvantagesPartnerSection = () => {

    const {width} = useWindowSize();

    return (
        <SectionBoxSd>
            <TextBoxSd>
                <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>
                    Мы гарантируем нашим партнерам
                </TypographyUI.H2>
                {width > LAPTOP_MOBILE_POINTER && (
                    <AdvantagesPartnerSectionInfo>
                        <AdvantagesPartnerSectionInfoBtn
                            onClick={() => {
                                document.querySelector('#quizSection').scrollIntoView({behavior: 'smooth'});
                            }}
                        >
                            Оставить заявку
                        </AdvantagesPartnerSectionInfoBtn>
                        <AdvantagesPartnerSectionInfoDesc>
                            * Единоразовая выплата для физических лиц — через три месяца
                        </AdvantagesPartnerSectionInfoDesc>
                    </AdvantagesPartnerSectionInfo>
                )}
            </TextBoxSd>
            <SectionItems>
                <SectionItem>
                    <SectionItemContent>
                        <TypographyUI.H4Bold className="title">Ежемесячные выплаты на счет</TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular className="description">В программе нет отложенных на год платежей.
                            Мы посчитаем оборот приведенного клиента и отправим часть вашей комиссии уже через
                            месяц.*</TypographyUI.Body1Regular>
                    </SectionItemContent>
                    <SectionItemImage>
                        <img src={srcImg1} alt={''}/>
                    </SectionItemImage>
                </SectionItem>

                <SectionItem>
                    <SectionItemContent>
                        <TypographyUI.H4Bold className="title">Простой и прозрачный алгоритм</TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular className="description">Мы заключаем партнерский договор и отправляем
                            акт на каждую выплату бонусов по программе. Вы получаете официальный
                            доход</TypographyUI.Body1Regular>
                    </SectionItemContent>
                    <SectionItemImage>
                        <img src={srcImg2} alt={''}/>
                    </SectionItemImage>
                </SectionItem>

                <SectionItem>
                    <SectionItemContent>
                        <TypographyUI.H4Bold className="title">Возможность увеличения дохода</TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular className="description">Чем больше оборот компании, тем выше ваш
                            доход. Количество приглашенных компаний не ограничено</TypographyUI.Body1Regular>
                    </SectionItemContent>
                    <SectionItemImage>
                        <img src={srcImg3} alt={''}/>
                    </SectionItemImage>
                </SectionItem>

                <SectionItem>
                    <SectionItemContent>
                        <TypographyUI.H4Bold className="title">Конфиденциальность</TypographyUI.H4Bold>
                        <TypographyUI.Body1Regular className="description">Партнеры по бизнесу или руководители не
                            узнают о вашей комиссии от их оборота — мы гарантируем
                            конфиденциальность</TypographyUI.Body1Regular>
                    </SectionItemContent>
                    <SectionItemImage>
                        <img src={srcImg4} alt={''}/>
                    </SectionItemImage>
                </SectionItem>

            </SectionItems>

            {width <= LAPTOP_MOBILE_POINTER && (
                <AdvantagesPartnerSectionInfo>
                    <AdvantagesPartnerSectionInfoBtn
                        onClick={() => {
                            document.querySelector('#quizSection').scrollIntoView({behavior: 'smooth'});
                        }}
                    >
                        Оставить заявку
                    </AdvantagesPartnerSectionInfoBtn>
                    <AdvantagesPartnerSectionInfoDesc>
                        * Единоразовая выплата для физических лиц — через три месяца
                    </AdvantagesPartnerSectionInfoDesc>
                </AdvantagesPartnerSectionInfo>
            )}


        </SectionBoxSd>
    )
};

const SectionBoxSd = styled.section`
    display: flex;
    transition: all 0.3s;
    padding-top: 140px;
    flex-direction: row;
    gap: 71px;
    padding-bottom: 120px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-top: 80px;
        padding-bottom: 60px;
        flex-direction: column;
        gap: 0;
    }
`;

const TextBoxSd = styled.div`
    max-width: 417px;
    width: 100%;
    margin-bottom: 56px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
        margin-bottom: 40px;
        gap: 16px;
    }
`;

const SectionItems = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
`;


const SectionItem = styled.div`
    background: var(--background-main-grey);
    padding: 24px 37px 24px 28px;
    border-radius: 20px;
    display: flex;
    width: 655px;
    height: 172px;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        height: 100%;
        flex-direction: column-reverse;
        gap: 16px;
        align-items: flex-start;
        padding: 24px 28px;
    }
`;

const SectionItemContent = styled.div`
    max-width: 414px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }

    .title {
        margin-bottom: 16px;
    }

    .description {
        color: var(--text-secondary);
    }
`;

const AdvantagesPartnerSectionInfo = styled.div`
    margin-top: 60px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-top: 40px;
    }
`;

const AdvantagesPartnerSectionInfoBtn = styled(ButtonUI)`
    width: 276px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const AdvantagesPartnerSectionInfoDesc = styled(TypographyUI.Body2Regular)`
    color: var(--text-secondary);
    margin-top: 20px;
    max-width: 325px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 100%;
    }
`;

const SectionItemImage = styled.div`
    img {
        width: 116px;
        @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
            width: 60px !important;
        }
    }
`;
