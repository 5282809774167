import styled from "styled-components";
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonUI, LinkUI, TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER, walletLink} from "@tools/constants";
import {useWindowSize} from "@tools/hooks";
import {AdaptiveLinkCt} from "@components";

export const HowWorkSection = () => {

    const {width} = useWindowSize();

    return (
        <HowWorkSectionContainer>
        <HowWorkSectionItem style={{
            'width': '100%',
            'height': width && width > LAPTOP_MOBILE_POINTER ? '438px' : '100%',
            'background': 'var(--background-second)',
        }}>
            <HowWorkSectionItemInfo
                style={{'maxWidth': width && width > LAPTOP_MOBILE_POINTER ? '44%' : '100%'}}>
                <TypographyUI.H3 className="title" swichPoint={LAPTOP_MOBILE_POINTER}>Как это работает?</TypographyUI.H3>
                <HowWorkSectionItemInfoDescription>
                    «Бизнес» — сервис для компаний, которые работают с самозанятыми. Подготовим и проверим документы, проведем выплаты, сформируем чеки и акты.<br/><br/>Мы берем от 1% комиссии за переводы исполнителям. Если приведете нам клиента — часть комиссии отдадим вам
                </HowWorkSectionItemInfoDescription>
                <AdaptiveLinkCt to={'/business'}>
                    <HowWorkSectionBtn>Подробнее о платформе</HowWorkSectionBtn>
                </AdaptiveLinkCt>
            </HowWorkSectionItemInfo>

            <HowWorkSectionItemImage style={{
                'display': width && width > LAPTOP_MOBILE_POINTER ? 'block' : 'block',
                'position': width && width > LAPTOP_MOBILE_POINTER ? 'absolute' : 'relative',
                'right': width && width > LAPTOP_MOBILE_POINTER ? '0' : '0',
                'width': width && width > LAPTOP_MOBILE_POINTER ? '646px' : 'auto',
                'bottom': width && width > LAPTOP_MOBILE_POINTER ? '-10px' : '0'
            }}>
                <StaticImage
                    src="../../../images/partner/HowWorkSectionImage.png"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    layout="constrained"
                    objectFit="contain"
                    alt=""
                />
            </HowWorkSectionItemImage>
        </HowWorkSectionItem>
        </HowWorkSectionContainer>
    )
};

const HowWorkSectionContainer = styled.section`
    margin: 160px 0 100px;
    @media (max-width: 609px) {
        margin: 80px -19px 20px;
    }
`;

const HowWorkSectionItem = styled.div`
    padding-top: 30px;
    padding-left: 28px;
    padding-right: 28px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 609px) {
        border-radius: 0px;
    }
`;

const HowWorkSectionItemInfo = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
`;

const HowWorkSectionItemInfoDescription = styled(TypographyUI.Body1Regular)`
    margin-top: 16px;
    color: var(--text-secondary);
`;

const HowWorkSectionItemImage = styled.div`
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 300px;
        margin: 0 auto;
        padding: 20px 0 0;
    }

`;

const HowWorkSectionBtn = styled(ButtonUI)`
    width: 276px;
    margin-top: 30px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
        margin-bottom: 40px;
    }
`;
