import {TypographyUI} from "@ui";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import React from "react";
import {useWindowSize} from "@tools/hooks";
import styled from "styled-components";

import num1Img from "@images/partner/HowBePartnerSectionImage1.svg";
import num2Img from "@images/partner/HowBePartnerSectionImage2.svg";
import num3Img from "@images/partner/HowBePartnerSectionImage3.svg";

export const HowBePartnerSection = () => {

    const {width} = useWindowSize();

    return (
        <HowBePartnerSectionContainer>
            <HowBePartnerSectionTitle swichPoint={LAPTOP_MOBILE_POINTER}>
                Как стать нашим партнером
            </HowBePartnerSectionTitle>

            <HowBePartnerSectionItems>
                <HowBePartnerSectionItem>
                    <HowBePartnerSectionItemImg
                        src={num1Img}
                        alt="num-1"
                    />
                    <HowBePartnerSectionItemTitle>
                        Отправить заявку
                    </HowBePartnerSectionItemTitle>
                </HowBePartnerSectionItem>

                <HowBePartnerSectionItem>
                    <HowBePartnerSectionItemImg
                        src={num2Img}
                        alt="num-2"
                    />
                    <HowBePartnerSectionItemTitle>
                        Подписать договор партнерской программы
                    </HowBePartnerSectionItemTitle>
                </HowBePartnerSectionItem>

                <HowBePartnerSectionItem>
                    <HowBePartnerSectionItemImg
                        src={num3Img}
                        alt="num-3"
                    />
                    <HowBePartnerSectionItemTitle>
                        Получить деньги на свой счет
                    </HowBePartnerSectionItemTitle>
                </HowBePartnerSectionItem>

            </HowBePartnerSectionItems>

        </HowBePartnerSectionContainer>
    )
};

const HowBePartnerSectionContainer = styled.section`
    margin: 0px 0 100px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin: 100px 0 100px;
    }
`;

const HowBePartnerSectionTitle = styled(TypographyUI.H2)`
    text-align: center;
    margin-bottom: 75px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 40px;
    }
`

const HowBePartnerSectionItems = styled.div`
    display: flex;
    gap: 32px;
    justify-content: center;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
    }
`;

const HowBePartnerSectionItem = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 358px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;

const HowBePartnerSectionItemImg = styled.img`
    width: 72px;
    height: 72px;
    margin-bottom: 32px;
`;

const HowBePartnerSectionItemTitle = styled(TypographyUI.H4Bold)`
    padding-bottom: 12px;
`;

const HowBePartnerSectionItemDescription = styled(TypographyUI.Body1Regular)`
    color: var(--text-secondary);
`;
